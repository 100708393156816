import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"400"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"error","dark":"","dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Eliminar imagen")])],1),_c(VCardText,{staticClass:"mt-2"},[_c('p',[_vm._v(" ¿Estás segur@ que deseas eliminar la imagen seleccionada? Una vez borrada, la imagen "),_c('strong',[_vm._v("se dejará de mostrar")]),_vm._v(" en los lugares donde la insertaste. ")])]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"secondary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("No, cancelar")]),_c(VBtn,{attrs:{"color":"error","small":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.eliminar()}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('span',[_vm._v("Sí, eliminar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }