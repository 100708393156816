<template>
  <v-dialog v-model="mostrar" persistent width="400">
    <v-card>
      <v-toolbar color="error" dark dense flat>
        <v-toolbar-title>Eliminar imagen</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-2">
        <p>
          ¿Estás segur@ que deseas eliminar la imagen seleccionada? Una vez
          borrada, la imagen <strong>se dejará de mostrar</strong> en los
          lugares donde la insertaste.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >No, cancelar</v-btn
        >
        <v-btn
          color="error"
          small
          :disabled="loading"
          :loading="loading"
          @click="eliminar()"
        >
          <v-icon small class="mr-1">mdi-delete</v-icon>
          <span>Sí, eliminar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarImagenContenido } from "./imagenesContenido.service";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    tipoContenido: { type: String, required: true },
    urlImagen: { type: String, required: true },
    idContenido: { type: String, required: true }
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    async eliminar() {
      this.loading = true;

      try {
        const serverResponse = await eliminarImagenContenido({
          tipoContenido: this.tipoContenido,
          urlImagen: this.urlImagen,
          idContenido: this.idContenido
        });
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("imagenEliminada", serverResponse.imagen);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
